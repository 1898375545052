import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import FormikTextField from '@components/Formik/FormikTextField';
import FormikSelect from '@components/Formik/FormikSelect';
import DialogWithClose from '@components/Dialog/DialogWithClose';
import { WorkflowContributorAccess } from '@views/constants';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import Fetching from '@components/Fetching/Fetching';

export default function AccountForm({
  fetching,
  title,
  actionButtonText,
  defaultActionButtonText,
  roles,
  groups,
  disableEmail,
  hideEmptyList,
  showWorkflowAccess,
  disabledWorkflowAccess,
  showSignatureWorkflowAccess = false,
  fetch,
}) {
  return (
    <DialogWithClose
      titleLabel={title}
      dialogActions={
        <Button type="submit" disabled={fetching || fetch?.fetching}>
          <FormattedMessage
            id={actionButtonText}
            defaultMessage={defaultActionButtonText}
          />
        </Button>
      }
    >
      <Stack gap={2}>
        <ErrorMessage error={fetch?.error} />
        <FormikTextField
          required
          name="firstName"
          label={
            <FormattedMessage id="UserAccounts.AccountsTable.Columns.FirstName.Label" />
          }
          color="secondary"
          margin="dense"
          disabled={fetching || fetch?.fetching}
        />
        <FormikTextField
          required
          name="lastName"
          label={
            <FormattedMessage id="UserAccounts.AccountsTable.Columns.Nom.Label" />
          }
          color="secondary"
          margin="dense"
          disabled={fetching || fetch?.fetching}
        />
        <FormikTextField
          required={!disableEmail}
          name="email"
          label={
            <FormattedMessage id="UserAccounts.AccountsTable.Columns.Email.Label" />
          }
          color="secondary"
          margin="dense"
          disabled={disableEmail || fetching || fetch?.fetching}
        />
        <FormikTextField
          name="phoneNumber"
          label={
            <FormattedMessage id="UserAccounts.AccountsTable.Columns.PhoneNumber.Label" />
          }
          color="secondary"
          margin="dense"
          disabled={fetching || fetch?.fetching}
        />
        {hideEmptyList && (roles === undefined || roles.length === 0) ? null : (
          <FormikSelect
            label={
              <FormattedMessage id="UserAccounts.AccountsTable.Columns.Role.Label" />
            }
            name="roleIds"
            margin="dense"
            disabled={fetching || fetch?.fetching}
            multiple
          >
            {roles?.length
              ? roles.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))
              : null}
          </FormikSelect>
        )}
        {hideEmptyList &&
        (groups === undefined || groups?.length === 0) ? null : (
          <FormikSelect
            label={
              <FormattedMessage id="UserAccounts.AccountsTable.Columns.Group.Label" />
            }
            name="groupIds"
            margin="dense"
            disabled={fetching || fetch?.fetching}
            multiple
          >
            {groups?.length
              ? groups.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))
              : null}
          </FormikSelect>
        )}
        {showWorkflowAccess && (
          <FormikSelect
            margin="dense"
            name="workflowAccess"
            required
            disabled={disabledWorkflowAccess}
            label={
              <FormattedMessage id="Workflow.Contributor.Fields.Access.Label" />
            }
          >
            {Object.values(WorkflowContributorAccess)
              .filter(v =>
                showSignatureWorkflowAccess ? Number(v) > 0  : Number(v) != 1 && Number(v) != 7
              )
              .map((key, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`access-${index}`} value={key}>
                  <FormattedMessage
                    id={`Workflow.Contributor.Fields.Access.${key}.Label`}
                  />
                </MenuItem>
              ))}
          </FormikSelect>
        )}
        <Fetching fetching={fetching || fetch?.fetching} />
      </Stack>
    </DialogWithClose>
  );
}
